import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import store from '../store'

Vue.use(Vuex)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/VendorLaunchHome")
  },
  {
    path: '/category/:categorySlug',
    name: 'Category',
    meta: {
      requiresAuth: false,
      title: 'Program Vendors - HDS'
    },
    component: () => import("@/views/Category"),
    props: true
  },
  {
    path: '/about-the-program',
    name: 'About',
    meta: {
      requiresAuth: false,
      title: 'About the Program - HDS',

    },
    component: () => import("@/views/About")
  },
  {  
    path: '/about-enterprise-services',
    name: 'Enterprise Services',
    meta: {
      requiresAuth: false,
      title: 'Enterprise Services - HDS',

    },
    component: () => import("@/views/EnterpriseServices")
  },
  {  
    path: '/about-digital-spend-optimization',
    name: 'Digital Spend Optimization',
    meta: {
      requiresAuth: false,
      title: 'Digital Spend Optimization - HDS',

    },
    component: () => import("@/views/DigitalSpendOptimization")
  },
  {
    path: '/how-to-enroll',
    name: 'How to Enroll',
    meta: {
      requiresAuth: false,
      title: 'How to Enroll - HDS',

    },
    component: () => import("@/views/HowToEnroll")
  },
  {  
    path: '/frequently-asked-questions',
    name: 'Frequently Asked Questions',
    meta: {
      requiresAuth: false,
      title: 'Frequently Asked Questions - HDS',

    },
    component: () => import("@/views/Faq")
  },
/*
    {  
    path: '/category/call-tracking',
    name: 'Call Tracking',
    meta: {
      requiresAuth: false,
      title: 'Call Tracking',
      parent: "marketplace services"
    },
    component: () => import("@/views/MarketplaceServices")
  },
 */

  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresAuth: false,
      title: 'Login - HDS'
    },
    component: () => import("@/views/Login")
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      requiresAuth: false,
      title: 'Logout - HDS'
    },
    component: () => import("@/views/Logout")
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
      title: 'Dashboard - HDS'
    },
    component: () => import("@/views/dashboard/Dashboard")
  },
  {
    path: '/categories',
    name: 'Categories',
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/dashboard/Categories")
  },
  {
    path: '/categories/new',
    name: 'New Category',
    meta: {
      requiresAuth: true,
      title: 'New Category - HDS'
    },
    component: () => import("@/views/dashboard/EditCategory")
  }, {
    path: '/categories/edit/:categoryId',
    name: 'Edit Category',
    meta: {
      requiresAuth: true,
      title: 'Edit Category - HDS'
    },
    component: () => import("@/views/dashboard/EditCategory"),
    props: true
  },
  {
    path: '/vendors',
    name: 'Vendors',
    meta: {
      requiresAuth: true,
      title: 'Vendors - HDS'
    },
    component: () => import("@/views/dashboard/Vendors")
  },
  {
    path: '/vendors/new',
    name: 'New Vendor',
    meta: {
      requiresAuth: true,
      title: 'New Vendor - HDS'
    },
    component: () => import("@/views/dashboard/EditVendor")
  }, {
    path: '/vendors/edit/:vendorId',
    name: 'Edit Vendor',
    meta: {
      requiresAuth: true,
      title: 'Edit Vendor - HDS'
    },
    component: () => import("@/views/dashboard/EditVendor"),
    props: true
  },
  {
    path: '/inquiries',
    name: 'Inquiries',
    meta: {
      requiresAuth: true,
      title: 'Inquiries - HDS'
    },
    component: () => import("@/views/dashboard/Inquiries")
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true,
      title: 'Users - HDS'
    },
    component: () => import("@/views/dashboard/Users")
  },
  {
    path: '/log',
    name: 'Log',
    meta: {
      requiresAuth: true,
      title: 'Activity Log - HDS'
    },
    component: () => import("@/views/dashboard/Log")
  },
  {
    path: '/users/new',
    name: 'New User',
    meta: {
      requiresAuth: true,
      title: 'New User - HDS'
    },
    component: () => import("@/views/dashboard/EditUser")
  },
  {
    path: '/users/edit/:userId',
    name: 'Edit User',
    meta: {
      requiresAuth: true,
      title: 'Edit User - HDS'
    },
    component: () => import("@/views/dashboard/EditUser"),
    props: true
  },
  {
    path: '/events',
    name: 'Events',
    meta: {
      requiresAuth: true,
      title: 'Events - HDS'
    },
    component: () => import("@/views/dashboard/Events")
  },
  {
    path: '/events/new',
    name: 'New Event',
    meta: {
      requiresAuth: true,
      title: 'New Event - HDS'
    },
    component: () => import("@/views/dashboard/EditEvent")
  },
  {
    path: '/newsletters',
    name: 'Newsletters',
    meta: {
      requiresAuth: true,
      title: 'Newsletters - HDS'
    },
    component: () => import("@/views/dashboard/Newsletters")
  },
  {
    path: '/newsletters/new',
    name: 'New Newsletter',
    meta: {
      requiresAuth: true,
      title: 'New Newsletter - HDS'
    },
    component: () => import("@/views/dashboard/EditNewsletter")
  },
  {
    path: '/events/edit/:eventId',
    name: 'Edit Event',
    meta: {
      requiresAuth: true,
      title: 'Edit Event - HDS'
    },
    component: () => import("@/views/dashboard/EditEvent"),
    props: true
  },
  {
    path: "*",
    name: "CatchAll",
    props: true,
    meta: {
      requiresAuth: false,
      title: 'Page not found - HDS'
    },
    component: () => import("@/views/CatchAll")
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _from, next) => {
  store.commit('closeEverything');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    store.dispatch('checkUser');
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router

